/* Coin Rain Animation */
@keyframes fall {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(100vh); /* Move coins to the bottom */
      opacity: 0;
    }
  }
  
  /* +1 Animation */
  @keyframes popUp {
    0% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateY(-30px) scale(1.2); /* Move up and enlarge */
    }
  }
  
  /* General Coin Styles */
  .coin {
    position: absolute;
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
    animation: fall linear infinite; /* Falling animation */
    user-select: none;
    opacity: 0;
  }
  
  /* +1 Animation for clicked coins */
  .plus-one {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    color: orange;
    animation: popUp 1s forwards; /* Disappears after 1 second */
    pointer-events: none;
    user-select: none;
  }
  
  .coin-rain {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    user-select: none;
  }