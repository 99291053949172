body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

h1, p {
  margin: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  img {
    width: 90%;
  }
}
